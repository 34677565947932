import React, { useEffect, useState } from 'react';
import { fetchPveEfficiencyData, fetchLifeEfficiencyData } from '../services/LostArkApi';
import { Helmet } from 'react-helmet-async';
import '../styles/HomePage.css';
import AdComponent from '../components/AdComponent'; // 광고 컴포넌트 추가

function HomePage() {
  const [mergedData, setMergedData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [pveData, lifeData] = await Promise.all([
          fetchPveEfficiencyData(),
          fetchLifeEfficiencyData(),
        ]);

        const combinedData = [...pveData, ...lifeData]
          .map((content) => ({
            name: content.name,
            gold_per_hour: content.gold_per_hour,
          }))
          .sort((a, b) => b.gold_per_hour - a.gold_per_hour);

        setMergedData(combinedData);
      } catch (error) {
        console.error('데이터를 가져오는 중 오류가 발생했습니다:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) {
    return <div className="loading-container">데이터를 불러오는 중입니다...</div>;
  }
  const pageTitle = '로스트 골드';
  const pageDescription =
  '로스트 골드 | 효율적인 골드 수급과 소비';
  const keywords = '로스트골드, 시골 계산기';


  return (
    <div className="home-page">
      <Helmet>
        <title>{pageTitle}</title>
         <meta name="description" content={pageDescription} />
         <meta name="keywords" content={keywords} />    
      </Helmet>
      {/* 상단 타이틀 */}
      <div className="home-header">
        <h1 className="home-title">
          로스트 골드 <img src="/clock_4.png" alt="사이트" className="title-icon" />
        </h1>
        <p className="home-subtitle">시간당 골드 - 한 시간 동안 얻을 수 있는 골드 획득량</p>

        <p className="home-subtitle2">
          <br/>2025.01.26 pve 효율 계산기 1700컨텐츠 추가
          <br/>2025.01.15 생활 도구 계산기 "보물주머니" 옵션 하향조정
        </p>
      </div>

      {/* 광고: 테이블 상단 */}
      <AdComponent
        className="horizontal-ad"
        adClient="ca-pub-4349329556962059"
        adSlot="5655624736"
        adType="horizontal"        
      />

      {/* 컨텐츠 테이블 */}
      <div className="home-content">
        <table className="home-table">
          <thead>
            <tr>
              <th>컨텐츠명</th>
              <th>시간당 골드</th>
            </tr>
          </thead>
          <tbody>
            {mergedData.map((content, index) => (
              <tr key={index}>
                <td>{content.name}</td>
                <td>{content.gold_per_hour.toLocaleString()} 골드</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default HomePage;
